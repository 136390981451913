import React from "react";
import { LinkPrimary } from '../Button';
import styled from "styled-components";
import google from "./google.svg";
import facebook from "./facebook.svg";
import twitter from "./twitter.svg";

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FormContainer = styled.div`
  background: linear-gradient(352.94deg, rgba(181, 198, 229, 0.4) -0.55%, rgba(239, 248, 255, 0.4) 42.7%), #EFF8FF;
  border-radius: 8px;
  max-width: 380px;
  width: 100%;
  padding: 48px 18px;
  margin: 0 auto 19px;
  text-align: center;
  position: absolute;
  top: 400px;
  z-index: 50;

  @media screen and (max-width: 470px) {
    max-width: calc(100% - 60px);
  }

  &:after {
    content: "";
    display: block;
    width: calc(100% - 12px);
    left: 6px;
    bottom: -7px;
    position: absolute;
    background: #D5E1F4;
    height: 7px;
    border-radius: 0 0 8px 8px;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    color: #405071;
    margin: 0 0 24px 0;
  }
`;

const FormStep = styled.div`

`;

const FormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 212px;
  margin: 24px auto;

  > a {
    text-decoration: none;
    display: block;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    border-radius: 50%;

    &.facebook {
      background: url(${facebook}) center center no-repeat;
      box-shadow: 0 6px 20px rgba(60, 85, 165, 0.4);
    }
    &.google {
      background: url(${google}) center center no-repeat;
      box-shadow: 0 6px 20px rgba(94, 177, 239, 0.3);
    }
    &.twitter {
      background: url(${twitter}) center center no-repeat;
      box-shadow: 0 6px 20px rgba(94, 177, 239, 0.3);
    }

    img {
      width: 100px;
      height: 100px;
    }
  }
`;

const RegFormVariantA = ({
  formTitle,
  formOr,
  formButton,
  formGoogleUrl,
  formFacebookUrl,
  formTwitterUrl,
  promoUrl
}) => {

  return (
    <FormWrapper>
      <FormContainer>
        <FormStep>
          <h2>{formTitle}</h2>
          <FormButtons>
            <a href={formFacebookUrl} className="facebook" />
            <a href={formTwitterUrl} className="twitter" />
            <a href={formGoogleUrl} className="google" />
          </FormButtons>
          <h2>{formOr}</h2>
          <LinkPrimary href={promoUrl} style={{ maxWidth: 212, margin: "0 auto" }}>
            {formButton}
          </LinkPrimary>
        </FormStep>
      </FormContainer>
    </FormWrapper>
  );
};

export default RegFormVariantA;
